<template>
  <div class="card-depo mt-2">
    <div
      v-if="Nextstep == 0"
      class="section-wrap pd-v-20"
    >
      <div class="section-title-l m-no-padding"><strong class="font-normal">เลือกธนาคารสำหรับฝาก</strong>
        <div class="clearfix" />
      </div>

      <div class="box-radio-list">
        <div
          class="radio-items"
          :class="`${bank_type === 'kbank' ? 'checked' : ''}`"
        >
          <label class="input-container radio-area d-flex align-items-center">
            <div class="inner-radio">
              <input
                v-model="bank_type"
                type="radio"
                name="bank_type"
                value="kbank"
                class="radio radio-bank"
                style="margin-right: 5px; margin-top: 7px;"
              ><span class="radiomark" />
            </div>
            <img
              src="/bankIcon/kbank.svg"
              style="background:#138f2d;width:32px;border-radius:.25rem;padding:7px;margin-right:10px"
            > <strong class="text-radio font-weight-bolder font-medium-1">ธนาคารกสิกรไทย</strong>
          </label>
        </div>
      </div>

      <div class="section-wrap mt-1">
        <div class="section-title-l m-no-padding alert alert-danger">
          <strong><i class="fal fa-exclamation-triangle" /> การโอน แจ้งฝาก ต้องเป็นบัญชีที่สมัครเท่านั้น</strong>
        </div>

        <div v-if="bank_type === 'kbank'">
          <div class="form-group-10 form-group has-feedback">
            <div class="input-2-box">
              <input
                v-model="amount"
                :disabled="Nextstep == 1"
                type="tel"
                name="amount"
                autocomplete="off"
                placeholder="ระบุจำนวนเงินที่ต้องการฝาก"
                class="form-control"
                data-fv-field="amount"
              ><i
                class="form-control-feedback"
                data-fv-icon-for="amount"
                style="display: none;"
              />
              <div class="text-control">
                THB
              </div>
            </div>
          </div>
          <div class="alert alert-warning d-flex align-items-center">
            <i class="fas fa-exclamation-triangle" />
            กรุณาระบุยอดการฝากเป็นจำนวนเต็มเท่านั้น ระบบจะทำการสุ่มทศนิยมให้เอง
          </div>

          <div class="box-form-button">
            <button
              :disabled="Nextstep == 1 || amount == 0"
              type="button"
              class="btn btn-warning btn-block btn-submit-money"
              style="background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728);"
              @click="SubmitDeposiWaitting()"
            >
              <strong style="color: #000; font-weight: 700">ตกลง</strong>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="Nextstep == 1"
      class="m-pd-h-5 step2"
    >
      <!-- <div> -->
      <div class="section-wrap pd-v-20">
        <div class="section-title-l m-no-padding"><strong class="font-normal">กรุณาโอนเงินจำนวน</strong>
          <div class="clearfix" />
        </div>
        <div class="mb-10">
          <strong class="font-large-2 color-green amount-random-decimal">{{ amountShow }}</strong> <strong class="font-heading color-green">บาท</strong>
        </div>
        <div class="alert alert-warning warning d-flex align-items-center"><i class="fas fa-exclamation-triangle" />
          <div class="text">
            โปรดระบุเศษให้ถูกต้อง
          </div>
        </div>
      </div>
      <div class="section-wrap">
        <div class="section-title-l m-no-padding"><strong class="font-normal">โอนเงินมาที่บัญชี</strong>
          <div class="clearfix" />
        </div>
        <div class="box-white pd-0 mb-20">
          <ul class="detail-list no-hover pd-0">
            <li class="d-flex align-items-center pd-10-15">
              <span
                id="logo-bank"
                class=""
              ><img
                src="/bankIcon/kbank.svg"
                style="background:#138f2d;width:32px;border-radius:.25rem;padding:7px;margin-right:10px"
              ></span>
              <div
                id="name-bank"
                class="text"
              >
                <strong class="text-radio">ธนาคารกสิกรไทย</strong>
              </div>
            </li>
            <li class="pd-10-15">
              <span class="font-sub-title color-sub">ชื่อบัญชี</span><br><strong
                id="account-name"
                class="font-heading"
              >{{ AgentData.dip_bank2_accname }}</strong>
            </li>
            <li class="pd-10-15">
              <span class="font-sub-title color-sub">เลขที่บัญชี</span><br><strong
                id="account-number"
                class="font-heading"
              >{{ AgentData.dip_bank2_accno }}</strong>

              <b-badge
                v-clipboard:copy="message"
                variant="light-primary"
                class="font-weight-bolder float-right"
                style="font-size: 1.2rem"
                @click="showToast1()"
              >
                <div class="d-flex align-items-center">
                  <i class="far fa-copy" />&nbsp;
                  คัดลอก
                </div>
              </b-badge>
            </li>
            <li class="pd-10-15">
              <span class="font-sub-title color-sub">จำนวนเงินที่ฝาก</span><br><strong class="font-heading amount-random-decimal">{{ amountShow }}</strong>
            </li>
          </ul>
        </div>
        <div class="mb-10">
          <div class="mb-10 color-green text-center">
            <strong>คุณมีเวลาโอนเงินภายใน  10 นาที</strong>
          </div>
          <div class="box-alert success text-center">
            <strong
              v-if="!CountTimeShow"
              class="font-large-1 countdown"
            >00 : 00 : 00</strong>
            <strong
              v-else
              class="font-large-1 countdown"
            >00 : {{ CountTimeShow }}</strong>
          </div>
        </div>
      </div>
      <div class="box-form-button">
        <button
          class="btn btn-warning btn-block mb-10"
          type="submit"
          style="background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728); color: #000 !important; font-weight: 700;"
          @click="UpdateDeposiWaitting()"
        >
          ยืนยันการโอนเงิน
        </button>
        <div
          class="text-center mt-1"
          @click="Cancel()"
        >
          <a>ยกเลิกรายการ</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BBadge,
  // BFormInput,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  components: {
    BBadge,
    // BFormInput,
  },
  data() {
    return {
      dataId: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      // เก็บข้อมูลแจ้งโอน
      userID: null,
      username: null,
      decimal: null,
      CountTimeShow: null,
      note: null,
      // ---------------------
      CountTime: null,
      transfertime: null,
      Nextstep: 0,
      amount: null,
      amountShow: 0,
      hours: null,
      min: null,
      minOption: [],
      hoursOption: [],
      // true_wallet: '',
      bank_type: '',
      click1: true,
      click2: false,
      num_true: '',
      message: '',
      x: 0,
      stamptime: 0,
      AgentData: {
        dip_bank_code: '',
        dip_bank_accno: '',
        dip_bank_accname: '',
        truemoney_acc: '',
        truemoney_name: '',
        bankname: '',
        bg: '',
        path_photo: '',
      },
    }
  },
  mounted() {
    this.ShowAgent()
    this.GetWaitting()
  },
  methods: {
    async GetWaitting() {
      try {
        const { data: res } = await this.$http.get(
          `DepositWaitting/ByUID/${this.userData.userID}`,
        )
        if (res) {
          // eslint-disable-next-line no-underscore-dangle
          this.dataId = res._id
          const now = momenttz()
          const duration = await momenttz.duration(now.diff(res.CountTime))
          const Min = await duration.asMinutes()
          // console.log(10 - Min)
          const time = await momenttz().add(-10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
          if (res.CountTime < time) {
            this.Cancel()
          } else {
            this.startTimer(60 * (10 - Min))
          }

          this.amountShow = res.amount
          const checkhours = await momenttz(res.CountTime).format('HH')
          const r = await momenttz(res.CountTime).add(1, 'hours').format('HH')
          // console.log(checkhours)
          const checkmin = await momenttz(res.CountTime).format('mm')
          if (checkmin > 50) {
            this.x = 1
          }
          // eslint-disable-next-line no-plusplus
          for (let i = Number(checkhours); i <= Number(checkhours) + this.x; i++) {
            if (this.stamptime === 1 && checkmin > 50) {
              this.hoursOption.push(r)
            } else {
              // eslint-disable-next-line no-plusplus
              this.stamptime++
              this.hoursOption.push(checkhours)
            }
          }
          // eslint-disable-next-line no-plusplus
          for (let i = Number(checkmin); i <= Number(checkmin) + 9; i++) {
            if (i === 60) {
              this.minOption.push('00')
            } else if (i === 61) {
              this.minOption.push('01')
            } else if (i === 62) {
              this.minOption.push('02')
            } else if (i === 63) {
              this.minOption.push('03')
            } else if (i === 64) {
              this.minOption.push('04')
            } else if (i === 65) {
              this.minOption.push('05')
            } else if (i === 66) {
              this.minOption.push('06')
            } else if (i === 67) {
              this.minOption.push('07')
            } else if (i === 68) {
              this.minOption.push('08')
            } else if (i === 69) {
              this.minOption.push('09')
            } else if (i === 70) {
              this.minOption.push('10')
            } else if (i === 0) {
              this.minOption.push('00')
            } else if (i === 1) {
              this.minOption.push('01')
            } else if (i === 2) {
              this.minOption.push('02')
            } else if (i === 3) {
              this.minOption.push('03')
            } else if (i === 4) {
              this.minOption.push('04')
            } else if (i === 5) {
              this.minOption.push('05')
            } else if (i === 6) {
              this.minOption.push('06')
            } else if (i === 7) {
              this.minOption.push('07')
            } else if (i === 8) {
              this.minOption.push('08')
            } else if (i === 9) {
              this.minOption.push('09')
            } else {
              this.minOption.push(i)
            }
          }
        }
        // console.log(this.minOption)
      } catch (err) {
        console.log(err)
      }
    },
    startTimer(duration) {
      let timer = duration
      let minutes
      let seconds
      this.intervalID = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        // console.log(seconds)
        this.CountTimeShow = `${minutes} : ${seconds}`

        // eslint-disable-next-line no-plusplus
        if (--timer < 0) {
          timer = duration
        }
        if (minutes === '00' && seconds === '00') {
          // console.log('Stop')
          clearInterval(this.intervalID)
          this.Nextstep = 0
          this.amountShow = 0
          this.amount = 0
          this.minOption = []
          this.hoursOption = []
          this.DelWaittingByUID()
        }
      }, 1000)
      this.Nextstep = 1
    },
    async RandomDecimal(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.dataId = data._id
      this.startTimer(60 * 10)
      this.amountShow = data.amount
      const checkhours = await momenttz(data.CountTime).format('HH')
      const r = await momenttz(data.CountTime).add(1, 'hours').format('HH')
      // console.log(checkhours)
      const checkmin = await momenttz(data.CountTime).format('mm')
      if (checkmin > 50) {
        this.x = 1
      }
      // eslint-disable-next-line no-plusplus
      for (let i = Number(checkhours); i <= Number(checkhours) + this.x; i++) {
        if (this.stamptime === 1 && checkmin > 50) {
          this.hoursOption.push(r)
        } else {
          // eslint-disable-next-line no-plusplus
          this.stamptime++
          this.hoursOption.push(checkhours)
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let i = Number(checkmin); i <= Number(checkmin) + 9; i++) {
        if (i === 60) {
          this.minOption.push('00')
        } else if (i === 61) {
          this.minOption.push('01')
        } else if (i === 62) {
          this.minOption.push('02')
        } else if (i === 63) {
          this.minOption.push('03')
        } else if (i === 64) {
          this.minOption.push('04')
        } else if (i === 65) {
          this.minOption.push('05')
        } else if (i === 66) {
          this.minOption.push('06')
        } else if (i === 67) {
          this.minOption.push('07')
        } else if (i === 68) {
          this.minOption.push('08')
        } else if (i === 69) {
          this.minOption.push('09')
        } else if (i === 70) {
          this.minOption.push('10')
        } else if (i === 0) {
          this.minOption.push('00')
        } else if (i === 1) {
          this.minOption.push('01')
        } else if (i === 2) {
          this.minOption.push('02')
        } else if (i === 3) {
          this.minOption.push('03')
        } else if (i === 4) {
          this.minOption.push('04')
        } else if (i === 5) {
          this.minOption.push('05')
        } else if (i === 6) {
          this.minOption.push('06')
        } else if (i === 7) {
          this.minOption.push('07')
        } else if (i === 8) {
          this.minOption.push('08')
        } else if (i === 9) {
          this.minOption.push('09')
        } else {
          this.minOption.push(i)
        }
      }
      this.Nextstep = 1
    },
    async DelWaittingByUID() {
      try {
        await this.$http.get(
          `DepositWaitting/DelByUID/${this.userData.userID}`,
        )
      } catch (err) {
        console.log(err)
      }
    },
    Cancel() {
      this.DelWaittingByUID()
      clearInterval(this.intervalID)
      this.minOption = []
      this.hoursOption = []
      this.Nextstep = 0
      this.amountShow = 0
      this.amount = 0
    },
    async SubmitDeposiWaitting() {
      try {
        const obj = {
          username: this.userData.username,
          userID: this.userData.userID,
          transfertime: null,
          CountTime: momenttz(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          amount: this.amount,
          note: null,
          status: 0,
        }
        const { data: response } = await this.$http.post(
          'DepositWaitting/store',
          obj,
        )
        this.RandomDecimal(response)
      } catch (err) {
        console.log(err)
      }
    },
    async UpdateDeposiWaitting() {
      this.transfertime = `${momenttz().format('YYYY-MM-DD')} ${this.hours}:${this.min}:00`
      try {
        const obj = {
          transfertime: this.transfertime,
          note: this.note,
          id: this.dataId,
        }
        const { data: response } = await this.$http.post(
          'DepositWaitting/Update',
          obj,
        )
        if (response) {
          this.$router.push('/Lottery')
          clearInterval(this.intervalID)
          this.minOption = []
          this.hoursOption = []
          this.Nextstep = 0
          this.amountShow = 0
          this.amount = 0
          this.dataId = null
          this.showToast('แจ้งโอนเงินสำเร็จ')
          this.$router.push('/Lottery')
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'member/agent/show',
        )
        if (response) {
          this.AgentData = response
          this.message = this.AgentData.dip_bank2_accno
          this.num_true = this.AgentData.truemoney_acc
        }
      } catch (err) {
        console.log(err)
      }
    },
    showToast1() {
      this.$toast.success('คัดลอกเลขบัญชีสำเร็จ', {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'far fa-copy',
        rtl: false,
      })
    },
    showToast(text) {
      this.$toast.success(text, {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'far fa-copy',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
.input-2-box .form-control {
    border-radius: 0;
    border: none;
    flex-grow: 1;
}
.form-control {
    padding: 10px;
    line-height: 22px;
    border-color: #E6E6E6;
    color: #000;
    font-size: 15px;
    height: 45px;
    font-weight: 700;
}
.form-control {
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.alert {
  border-radius: 4px;
  border: none;
  /* color: #fff !important; */
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  /* margin-left: 5px;
  margin-right: 5px; */
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.depo {
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.img-bank {
  margin: auto;
  background: rgb(71, 1, 104);
  padding: 5px;
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
.balance-true {
  margin: auto;
  border: 2px solid #FF6700;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.notice__list {
  font-size: 18px;
}
.notice__list {
  font-size: 0.85rem;
  margin: auto;
  max-width: 640px;
  background: linear-gradient(47deg, #accb32, #bdd65a);
  /* border: 1px solid rgb(255, 0, 0); */
  /* padding: 15px 24px; */
  /* border-radius: 10px; */
  color: rgb(0, 0, 0);
  margin-bottom: 0.75rem;
}
.notice__list p.alert {
  display: flex;
}
.btn-copy-2 {
  color: #000000;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.balance-cl {
  margin: auto;
  /* background-color: #4e2e7f; */
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.balance-bay {
  background-color: #ffc323;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .ftn {
    font-size: 1.2rem;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.font-res {
  font-size: 38px;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .font-res {
    font-size: 2rem;
  }
}
/* Background circles start */
.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}
</style>
